import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, InputGroup } from 'reactstrap';
import classnames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Reaptcha from 'reaptcha';

const axios = require('axios').default;

toast.configure({
  autoClose: 8000,
  draggable: false,
});

export class user_input_form extends Component {
  componentDidMount() {
    document.title = 'stopsms.ee';
  }

  state = {
    sender_name: '',
    receiver_country_code: '',
    receiver_num: '',
    verified: false,
    token: '',
    errors: {},
    help: false,
  };

  submitForm = (e) => {
    if (this.state.verified === false) {
      this.captcha.execute();
    } else {
      this.handleSubmit();
    }
  };

  handleChange = (e) => {
    if (!!this.state.errors[e.target.name]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors[e.target.name];
      this.setState({
        [e.target.name]: e.target.value,
        errors,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  onVerify = (recaptchaResponse) => {
    this.setState({ verified: true });
    if (!!this.state.errors.verified) {
      delete this.state.errors.verified;
    }
    this.setState({ token: recaptchaResponse });

    this.handleSubmit();
  };

  handleSubmit = (e) => {
    //e.preventDefault();

    let errors = {};
    if (this.state.sender_name.length === 0)
      errors.sender_name = 'Saatja nimi puudub!';
    if (this.state.receiver_country_code.length === 0)
      errors.receiver_country_code = 'Riigikood puudub!';
    if (this.state.receiver_num.length === 0)
      errors.receiver_num = 'Telefoni number puudub!';
    this.setState({ errors });

    const isValid = Object.keys(errors).length === 0;
    if (isValid) {
      const { sender_name, receiver_country_code, receiver_num, token } =
        this.state;
      axios
        .post('/php/removefromlist.php', {
          sender_name,
          receiver_country_code,
          receiver_num,
          token,
        })
        .then(function (response) {
          console.log(response.data);
          if (response.data['status'] === 'success') {
            toast.success('Päring oli edukas! Teie andmed on edastatud!');
          } else if (response.data['msg'] === 'no such sender') {
            toast.error('Sellise nimega saatjat ei leitud!');
          } else if (response.data['msg'] === 'user exists') {
            toast.error('Selline kanne juba on nimekirjas!');
          } else if (response.data['msg'] === 'captcha error') {
            toast.error('captcha error');
          } else {
            toast.error('Viga! Proovi uuesti!');
          }
        })
        .catch(function (error) {
          toast.error('Viga! Proovi uuesti!');
        });

      this.captcha.reset();
      this.setState({ verified: false });
      this.setState({ receiver_country_code: '' });
      this.setState({ receiver_num: '' });
      this.setState({ sender_name: '' });
    } else {
      toast.warn('Vead lünkades!');
    }
  };

  render() {
    const { help } = this.state;
    return (
      <React.Fragment>
        <ToastContainer position="top-center" autoClose={5000} />
        <Form id="postForm" onSubmit={this.handleSubmit}>
          <FormGroup
            className={classnames('mb-2 mr-sm-2 mb-sm-0', {
              error: !!this.state.errors.sender_name,
            })}
          >
            <Label for="sender_name" className="mr-sm-2">
              Sisesta <b>SAATJA NIMI</b> (ettevõte, bränd vm), millelt sõnum tuli!
            </Label>
            <Input
              type="text"
              name="sender_name"
              id="sender_name"
              value={this.state.sender_name}
              onChange={this.handleChange}
            />
          </FormGroup>
          <span>{this.state.errors.sender_name}</span>
          <br></br>
          <FormGroup
            className={classnames('mb-2 mr-sm-2 mb-sm-0', {
              error: !!this.state.errors.receiver_num,
            })}
          >
            <Label for="number" className="mr-sm-2">
              Esimesse lahtrisse sisesta <b>RIIGIKOOD</b> nt 372, teise lahtrisse sisesta <b>TELEFONINUMBER</b>
            </Label>
            <InputGroup>
              <Input
                type="number"
                name="receiver_country_code"
                id="country_code"
                value={this.state.receiver_country_code}
                onFocus={(e) => e.target.select()}
                onChange={this.handleChange}
              />
              <Input
                type="number"
                name="receiver_num"
                id="number"
                value={this.state.receiver_num}
                onChange={this.handleChange}
              />
            </InputGroup>
          </FormGroup>
          <span>{this.state.errors.receiver_country_code}</span>
          <span>{this.state.errors.receiver_num}</span>

          <br></br>
          <center>
            <Reaptcha
              ref={(e) => (this.captcha = e)}
              sitekey="6Lci6cAUAAAAAEnz73SwEYV8kPv3yq8QEFEewFnK"
              onVerify={this.onVerify}
              size="invisible"
            />
          </center>
          <span>{this.state.errors.verified}</span>
          <br></br>
        </Form>
        <center>
          <Button id="sub-button" onClick={this.submitForm}>
            Saada!
          </Button>
        </center>
        <br></br>
        <div className="help" style={{ display: help ? 'block' : 'none' }}>
          Sõnumitest loobumiseks sisesta siia SAATJA NIMI, millelt sõnum on
          tulnud ja TELEFONINUMBER, millele enam sõnumeid ei soovi saada.
        </div>
      </React.Fragment>
    );
  }
}

export default user_input_form;
